import React, { useState } from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

// import { Slider } from "../components/Slider"
import Stage from "../components/Stage"
import { BlueBanner } from "../components/Banner"

import Checklist from "../components/Checklist"

import Modal from "../components/Modal"

import Grid from "../components/Grid"
import Container from "../components/Container"
import { CFMLogoTitle } from "../components/CFMLogo"
import { Button, TextButton, TextLink } from "../components/Button"
import { Globe, Connections, Robotarm, Monitor } from "../components/Icon"
import { Simple as Card } from "../components/Card"
// import ApplicationTeaser from "../components/ApplicationTeaser"
import VideoPlayer from "../components/VideoPlayer"

import ArticlePreview from "../components/ArticlePreview"

import SafeAreaWrapper from "../components/SafeAreaWrapper"
import RecentNewFeatures from "../components/RecentNewFeatures"

import { BgImage } from "gbimage-bridge"

const IndexPage = ({
	data: {
		page,
		customers,
		allCustomers,
		testimonials,
		allPressCoverage,
		recentBlog,
		bannerImage,
		breakerImage,
		cfmOnDevicesImage,
	},
}) => {
	const [modalOpen, setModalOpen] = useState(false)

	const close = () => setModalOpen(false)
	const open = () => {
		window.umami && window.umami.track("video-introduction-to-cloud-fleet-manager")
		setModalOpen(true)
	}

	return (
		<Layout translucent={true}>
			{/* description={page.seo.description} */}
			<Seo title={page.seo.title} keywords={page.seo.keywords} />

			<Stage name="hero" className="overflow-visible min-h-[500px]">
				<div className="xl:container px-10 xl:px-30">
					<div className="px-10 md:px-20">
						<div className="pt-[160px] pb-[80px]">
							<div className="grid grid-cols-12 gap-x-20 md:gap-x-40 gap-y-50">
								<div className="col-span-12 md:col-start-2 md:col-end-12 lg:col-span-6 xl:col-span-5 xl:col-start-2 flex flex-col items-center lg:items-start max-w-[600px] md:max-w-none xl:mr-30 self-center">
									<h1 className="display1 text-white xl:pr-30 sm:text-center lg:text-left md:mt-[50px]">
										The cloud-based ship management solution
									</h1>
									<p className="text-white mt-20 mb-30 sm:text-center lg:text-left">
										All-in-one software for shipping companies that works
										entirely in web-browsers.
									</p>
									{/* <Link to="/demo">
										<TextButton light={true}>Schedule a demo</TextButton>
									</Link> */}
								</div>

								<div className="col-span-12 lg:col-span-6 flex flex-col justify-center">
									<div className="lg:absolute">
										<GatsbyImage
											alt="Cloud Fleet Manager running on devices"
											loading="eager"
											image={getImage(cfmOnDevicesImage)}
											className="lg:h-[350px] xl:h-[450px] z-10"
											imgStyle={{
												objectPosition: "left",
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Stage>

			<div className="bg-porcelain -mb-100">
				<Grid className="py-spacer-xl">
					<div className="col-span-12 md:col-start-2 md:col-end-12 lg:col-span-8 lg:pr-20 mb-spacer-md lg:mb-0 overflow-hidden">
						<div className="relative w-full h-[190px] md:h-[290px] lg:h-full xl:min-h-[350px] rounded-large overflow-hidden bg-tealblue group">
							{modalOpen && (
								<Modal modalOpen={modalOpen} handleClose={close}>
									<VideoPlayer
										autoPlay={true}
										source={{
											type: "video",
											sources: [
												{
													src: page.introductionVideo.video.localFile.url,
													provider: "html5",
												},
											],
										}}
									/>
								</Modal>
							)}

							<BgImage
								onClick={() => (modalOpen ? close() : open())}
								alt={page.introductionVideo.posterImage.alternativeText}
								Tag="div"
								loading="eager"
								className="bg-cover bg-center w-full h-full"
								image={getImage(page.introductionVideo.posterImage.localFile)}>
								<div className="w-full h-full flex flex-col justify-center items-center group hover:cursor-pointer relative">
									<div className="absolute top-0 bottom-0 left-0 right-0 bg-tealblue mix-blend-multiply opacity-70 transition duration-200 group-hover:opacity-90"></div>
									<div className="relative w-full flex flex-col justify-center items-center">
										<svg
											viewBox="0 0 100 100"
											xmlns="http://www.w3.org/2000/svg"
											className="text-white opacity-80 group-hover:opacity-100 fill-current
											w-[75px] h-[75px] lg:w-[100px] lg:h-[100px] transition duration-200 group-hover:scale-[1.1]">
											<path d="M50 100C22.386 100 0 77.614 0 50S22.386 0 50 0s50 22.386 50 50-22.386 50-50 50zm-9.273-68.33a1.206 1.206 0 00-.648-.189 1.18 1.18 0 00-1.19 1.169v34.7c0 .226.066.447.192.636a1.204 1.204 0 001.646.344l27.249-17.35a1.155 1.155 0 000-1.96l-27.25-17.35z" />
										</svg>
										{page.introductionVideo.title && (
											<div className="absolute -bottom-80 display3 text-white text-sm md:text-md lg:text-lg xl:text-xl leading-34font-bold">
												{page.introductionVideo.title}
											</div>
										)}
									</div>
								</div>
							</BgImage>
						</div>
					</div>

					<div className="col-span-12 lg:col-span-4">
						<h1 className="pb-10 border-solid border-iron border-b display2">
							Recent insights
						</h1>

						{recentBlog.edges.map(edge => (
							<div
								key={edge.node.id}
								className="mt-16 pb-16 border-solid border-iron border-b">
								<Link to={`/blog/${edge.node.slug}`}>
									<div className="flex flex-row gap-x-12">
										<>
											<div className="bg-porcelain w-[75px] h-[75px] rounded-medium overflow-hidden flex-none">
												<GatsbyImage
													className="rounded-medium w-full h-full overflow-hidden"
													imgClassName="overflow-hidden rounded-medium "
													objectFit="cover"
													loading="eager"
													objectPosition="center center"
													image={
														edge.node.teaser_image.imgixImage
															.gatsbyImageData
													}
													alt={edge.node.title}
													title={edge.node.title}
												/>
											</div>
											<ArticlePreview
												variant="tiny"
												// category={edge.node.frontmatter.category}
												date={edge.node.published_at}
												title={edge.node.title}
											/>
										</>
									</div>
								</Link>
							</div>
						))}
					</div>
				</Grid>

				<Container>
					<h1 className="pb-spacer-lg text-center display1">
						Drive digital transformation
					</h1>
				</Container>

				<Grid className="gap-y-40 xl:gap-y-0">
					<Card className="col-span-12 md:col-span-6 xl:col-span-3">
						<Monitor className="text-pictonblue w-80 h-80" />
						<h2 className="mt-spacer-sm mb-12 display3">Intuitive</h2>
						<p className="text-md">
							Cloud Fleet Manager is structured in a user-friendly and intuitive way.
							No more extensive trainings.
						</p>
					</Card>
					<Card className="col-span-12 md:col-span-6 xl:col-span-3">
						<Connections className="text-solaris w-80 h-80" />
						<h2 className="mt-spacer-sm mb-12 display3">All-in-one</h2>
						<p className="text-md">
							A multitude of applications that are optimised for all the different
							departments of your company.
						</p>
					</Card>
					<Card className="col-span-12 md:col-span-6 xl:col-span-3">
						{/* <Connections width={80} height={80} /> */}
						<Globe className="text-atlantis w-80 h-80" />
						<h2 className="mt-spacer-sm mb-12 display3">Worldwide access</h2>
						<p className="text-md">
							The system is completely web-based. Access modules and data regardless
							of time or location.
						</p>
					</Card>
					<Card className="col-span-12 md:col-span-6 xl:col-span-3">
						<Robotarm className="text-tealblue w-80 h-80" />
						<h2 className="mt-spacer-sm mb-12 display3">Process automation</h2>
						<p className="text-md">
							Free up your time and let Cloud Fleet Manager automatically take care of
							reoccurring tasks.
						</p>
					</Card>
				</Grid>
			</div>

			<div className="pt-100 bg-white">
				<Container className="my-spacer-xl">
					<h3 className="text-center display3">
						Trusted by ship management companies around the world
					</h3>

					<div className="mt-30 grid w-full grid-cols-2 gap-20 lg:grid-cols-4 lg:gap-12">
						{customers.edges.slice(0, 4).map(({ node }, index) => {
							return (
								<div
									key={`customer-${index}`}
									className="my-20 w-full flex flex-row items-center">
									<img
										loading="lazy"
										src={node.logo?.localFile.publicURL}
										title={node.name}
										alt={node.name}
										height="40"
										className="mx-auto h-30"
										key={`customer-logo-${index}`}
									/>
								</div>
							)
						})}
					</div>
				</Container>
			</div>

			<GatsbyImage
				image={breakerImage.gatsbyImageData}
				width={1800}
				alt="Cloud Fleet Manager in action"
				placeholder="blurred"
				className="mr-20 md:mr-30 lg:mr-160 h-240 md:h-400 lg:h-550 xl:h-700"
				imgClassName="rounded-r-medium overflow-hidden"
			/>

			<Grid className="gap-y-60" containerClassName="mt-80 mb-120 bg-white">
				{testimonials.edges
					.sort(() => 0.5 - Math.random())
					.slice(0, 1)
					.map(({ node }, index) => {
						return (
							<div
								key={`testimonial-${index}`}
								className="col-start-1 col-end-13 lg:col-start-2 lg:col-end-11">
								<h2 className="display2 font-bold">&bdquo;{node.quote}&ldquo;</h2>
								<div className="mt-30 flex flex-row items-center">
									{node.photo && (
										<GatsbyImage
											image={getImage(node.photo?.localFile)}
											alt={node.photo?.alternativeText}
											className="w-40 h-40 mr-12 rounded-full overflow-hidden"
											imgClassName="rounded-full overflow-hidden"
										/>
									)}
									<span className="text-nevada">
										{node.name}, {node.customer.name}
									</span>
								</div>
							</div>
						)
					})}
			</Grid>

			{/* Checklist */}
			<div className="bg-porcelain py-spacer-xl flex flex-col items-center">
				<Checklist
					title="What we can do for your company"
					text="We cover your needs. Increase collaboration and streamline processes for all different departments."
					items={[
						"Worldwide access",
						"Flexible expansion",
						"Data transfer from external systems",
						"Integration of the fleet into all processes",
						"Integration of your existing systems",
						"Access to Cloud Fleet Manager interfaces (API)",
						"Software as a Service (SaaS)",
						"Solutions for all your departments",
					]}
				/>
				<div className="mt-spacer-md text-center">
					<TextLink to="/faq">See our FAQ</TextLink>
				</div>
			</div>

			{/* Most popular applications */}
			{/* <Container className="pt-spacer-xl">
				<h1 className="text-left display1 pb-spacer-md">Most popular applications</h1>
				<div className="flex flex-row gap-20 md:gap-40">
					{[
						{
							title: "CFM Maintenance",
							text: "Planned maintenance made easy.",
							image: null,
							backgroundImage: maintenanceLifestyle,
							backgroundColorClass: "bg-package-maintenance",
						},
						{
							title: "CFM Cloud Crewing",
							text: "Boost efficiency by flexible crewing processes.",
							image: null,
							backgroundImage: crewingLifestyle,
							backgroundColorClass: "bg-package-crewing",
						},
						{
							title: "CFM Inspections & Audits",
							text: "Manage all your internal and external inspections",
							image: null,
							backgroundImage: qhseLifestyle,
							backgroundColorClass: "bg-package-qhse",
						},
						{
							title: "CFM Purchase",
							text: "Your OPEX under control. Always, anywhere.",
							image: null,
							backgroundImage: qhseLifestyle,
							backgroundColorClass: "bg-package-purchase",
						},
					].map((app, index) => (
						<ApplicationTeaser
							key={`teaser-${index}`}
							className="flex-none min-w-[380px]"
							title={app.title}
							text={app.text}
							image={app.image}
							backgroundImage={getImage(app.backgroundImage)}
							backgroundColorClass={app.backgroundColorClass}
						/>
					))}
				</div>
			</Container> */}

			<div className="bg-white">
				<RecentNewFeatures />
			</div>

			<BlueBanner
				className="my-spacer-xl"
				title={page.banner.title}
				subtitle={page.banner.pretext}
				image={page.banner.screenshot ? getImage(page.banner.screenshot.localFile) : null}
				callToAction="Schedule a demo"
				callToActionUrl="/demo"
			/>

			<div className="bg-white">
				<SafeAreaWrapper>
					<Grid className="mb-spacer-xl">
						<div className="col-span-12 lg:col-span-5 xl:col-span-4">
							<h3 className="pb-10 border-solid border-iron border-b display3">
								Recent press coverage
							</h3>
							{allPressCoverage.nodes.map(node => (
								<div
									key={node.id}
									className="mt-20 pb-20 border-solid border-iron border-b">
									{node.url ? (
										<a
											href={`${node.url}`}
											target="_blank"
											rel="noreferrer"
											alt={`${node.headline}`}
											title={`${node.headline}`}>
											<ArticlePreview
												variant="tiny"
												category={node.publication}
												date={node.released_at}
												title={node.headline}
											/>
										</a>
									) : (
										<ArticlePreview
											variant="tiny"
											category={node.publication}
											date={node.released_at}
											title={node.headline}
										/>
									)}
								</div>
							))}
						</div>

						<div className="bg-porcelain col-span-12 lg:col-span-7 xl:col-span-8 mt-60 lg:mt-0 rounded-large">
							<div className="grid grid-cols-12 lg:grid-cols-7 xl:grid-cols-8 gap-x-20 md:gap-x-40 h-full">
								<div className="col-span-12 md:col-span-5 lg:col-span-3 xl:col-span-3 relative z-10 h-full rounded-t-large md:rounded-tr-none md:rounded-bl-large overflow-hidden">
									<a
										className="h-full"
										href="/Hanseaticsoft-Cloud-Fleet-Manager-Product-Brochure.pdf"
										download>
										<StaticImage
											src="../images/brochure.jpg"
											height={450}
											alt="Cloud Fleet Manager product portfolio brochure"
											title="Cloud Fleet Manager product portfolio brochure"
											className="w-full h-full object-cover cursor-pointer duration-500 hover:scale-125 z-0"
										/>
									</a>
								</div>

								<div className="col-span-12 md:col-span-7 lg:col-span-4 xl:col-span-5 flex flex-col justify-center">
									<div className="px-30 py-40 md:pl-0 xl:pr-30">
										<div className="text-pictonblue captionBtn text-center md:text-left">
											Brochure
										</div>
										<h1 className="text-black my-12 text-center md:text-left display3">
											Cloud Fleet Manager Product Portfolio
										</h1>
										<p className="mb-30 text-center bodyp md:text-left mb-spacer-sm">
											Learn in depth what Cloud Fleet Manager has to offer and
											how the cloud-based approach helps shipping companies.
										</p>
										<div className="text-center md:text-left">
											<a
												href="/Hanseaticsoft-Cloud-Fleet-Manager-Product-Brochure.pdf"
												download>
												<Button
													primary={true}
													label="Download"
													data-umami-event="download-product-brochure"
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Grid>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiHomePage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}

			introductionVideo {
				title
				posterImage {
					alternativeText
					localFile {
						publicURL
						url
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 640)
						}
					}
				}

				video {
					localFile {
						publicURL
						url
					}
				}
			}
		}

		testimonials: allStrapiTestimonial(filter: { emphasize: { eq: true } }) {
			edges {
				node {
					name
					quote
					customer {
						name
					}
					photo {
						alternativeText
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(width: 40, height: 40)
							}
						}
					}
				}
			}
		}

		customers: allStrapiCustomer(
			filter: { emphasize: { eq: true }, logo: { mime: { eq: "image/svg+xml" } } }
		) {
			edges {
				node {
					name
					logo {
						alternativeText
						localFile {
							publicURL
						}
					}
				}
			}
		}

		allCustomers: allStrapiCustomer(filter: { logo: { mime: { eq: "image/svg+xml" } } }) {
			totalCount
		}

		recentBlog: allMongodbBloghanseaticsoftArticles(
			sort: { order: DESC, fields: published_at }
			filter: { isFuture: { eq: false } }
			limit: 4
		) {
			edges {
				node {
					title
					excerpt
					published_at(formatString: "MMMM DD, YYYY")
					slug
					teaser_image {
						imgixImage {
							gatsbyImageData(layout: CONSTRAINED, width: 75, height: 75)
						}
					}
				}
			}
		}

		allPressCoverage(limit: 3, sort: { fields: released_at, order: DESC }) {
			nodes {
				id
				url
				released_at(formatString: "MMMM DD, YYYY")
				publication
				headline
			}
		}

		breakerImage: imgixImage(
			url: "https://hanseaticsoftblog.blob.core.windows.net/website/gatsby/breaker.png"
		) {
			gatsbyImageData(layout: CONSTRAINED, width: 1800, placeholder: NONE, imgixParams: {})
		}

		cfmOnDevicesImage: file(relativePath: { eq: "cfm-on-devices.png" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 450, placeholder: NONE)
			}
		}
	}
`

export default IndexPage
