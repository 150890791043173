import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import CategoryLabel from "./CategoryLabel"

const ArticlePreview = ({
	variant,
	category,
	date,
	title,
	excerpt,
	featureImage,
	className,
	inlineDate = true,
	hasArrow = false,
}) => {
	switch (variant) {
		// case "featured":
		// 	return Featured({ category, date, title, excerpt, className })
		// 	break
		// case "small":
		// 	return Small({ category, date, title, excerpt, className })
		// 	break
		case "tiny":
			return Tiny({
				category,
				date,
				inlineDate,
				title,
				excerpt,
				featureImage,
				className,
				hasArrow,
			})
		default:
			return Snippet({ category, date, inlineDate, title, excerpt, className })
	}
}

// Home page
const Tiny = ({ category, date, title, excerpt, featureImage, hasArrow = false }) => (
	<div className="flex flex-row group duration-300 transition-all w-full items-start justify-start">
		{featureImage && (
			<span className="flex-none mr-12">
				<GatsbyImage
					image={featureImage.childImageSharp.gatsbyImageData}
					imgClassName="rounded-default overflow-hidden"
					alt={title}
					title={title}
				/>
			</span>
		)}
		<div className="flex-grow self-start mr-8 leading-none">
			<CategoryLabel category={category} date={date} />
			<p className="relative text-md text-jetblack font-medium mt-4 group-hover:text-pictonblue duration-300  leading-26 tracking-normal">
				{title}
			</p>
			{excerpt && <p className="small group-hover:text-pictonblue ">{excerpt}</p>}
		</div>
		{hasArrow && (
			<div className="flex-none self-end w-[44px] flex justify-center">
				<svg
					width="14"
					height="12"
					xmlns="http://www.w3.org/2000/svg"
					className="group-hover:-mr-10 duration-300 fill-current text-pictonblue">
					<path
						d="M14 6l-5.707 5.707-1.414-1.414L10.171 7H0V5h10.171L6.879 1.707 8.293.293z"
						fillRule="evenodd"
					/>
				</svg>
			</div>
		)}
	</div>
)

// Blog index, Press index
const Snippet = ({ category, date, inlineDate, title, excerpt = null }) => (
	<>
		{inlineDate ? (
			<CategoryLabel category={category} date={date} />
		) : (
			<CategoryLabel category={category} />
		)}

		<div
			className={`mt-8 mb-8 duration-300 transition-all group-hover:text-pictonblue ${
				excerpt ? "display3" : "bodyp text-base font-bold leading-28"
			}`}>
			{title}
		</div>
		{excerpt && (
			<p className="duration-300 transition-all jet-black group-hover:text-pictonblue text-md">
				{excerpt}
			</p>
		)}
		{!inlineDate && <CategoryLabel className="mt-4" date={date} />}
	</>
)

// const Featured = ({ category, date, title, excerpt }) => (
// 	<>
// 		<CategoryLabel category={category} date={date} />
// 		<h2 className="mt-8 mb-8 lg:mb-12 lg:mt-12 display2">{title}</h2>
// 		<p className="small">{excerpt}</p>
// 	</>
// )

// const Small = ({ category, date, title, excerpt }) => (
// 	<>
// 		<CategoryLabel category={category} date={date} />
// 		<p className="font-bold mt-8 mb-4">{title}</p>
// 		<p className="small">{excerpt}</p>
// 	</>
// )

export default ArticlePreview
