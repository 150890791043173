import React from "react"

const CategoryLabel = ({ category, date, className }) => (
	<div className={className}>
		{category && (
			<span className="uppercase captionSmall text-pictonblue mr-8 truncate">{category}</span>
		)}
		{date && (
			<span className="captionSmaller text-nevada group-hover:text-pictonblue duration-300 truncate">
				{date}
			</span>
		)}
	</div>
)

export default CategoryLabel
